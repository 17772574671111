/* Add this CSS to your component's CSS file or use a CSS-in-JS approach */
.custom-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%; /* Adjust the width as needed */
    max-width: 400px; /* Set a maximum width */
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .custom-modal h2 {
    font-size: 24px; /* Adjust the font size */
    color: #333; /* Adjust the text color */
    margin-bottom: 10px;
  }
  
  .custom-modal p {
    font-size: 16px; /* Adjust the font size */
    color: #666; /* Adjust the text color */
    margin-bottom: 20px;
  }
  
  .custom-modal button {
    background-color: #007bff; /* Button background color */
    color: #fff; /* Button text color */
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px; /* Adjust the font size */
    transition: background-color 0.3s ease-in-out;
  }
  
  .custom-modal button:hover {
    background-color: #0056b3; /* Hover background color */
  }
  